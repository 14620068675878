import lodash from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Collapse from 'react-collapse'
import { connect } from 'react-redux'
import { reduxForm, propTypes, getFormValues } from 'redux-form'
import moment from 'moment'
import classnames from 'classnames'

import Config from '../../helpers/config'
import { rejectReadonlyUser } from '../../helpers/permission'
import LabelWithTooltip from '../../components/common/LabelWithTooltip'
import ChatRoom from '../../components/chat/ChatRoom'
import RoomItem from '../../components/chat/RoomItem'
import { addNotice } from '../../actions/notice'
import {
  fetchOperatorRooms,
  fetchRoom,
  setCurrentRoom,
  clearRoom,
  fetchMessages,
  sendOperatorMessage,
  storeMessage,
  pickUp,
  hungUp,
  clearUnreadMessage,
  updateUnreadStatus,
  setAutoScroll,
} from '../../actions/chat'
import { isFetching } from '../../helpers/selector'
import WebSocketDevice from '../../helpers/awsIoT'
import { getCredentials } from '../../helpers/sessionHelper'

const validate = data => {
  const errors = {}
  if (!data.text) {
    errors.text = 'validate.required'
  }

  return errors
}

export class Operator extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    ...propTypes,
    account: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    rooms: PropTypes.array.isRequired,
    currentRoom: PropTypes.object,
    messages: PropTypes.array,
    isAutoScroll: PropTypes.bool,
    formValues: PropTypes.object,
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }

  constructor() {
    super()
    this.state = {
      isOpenAssignedTab: true,
      isOpenCallingTab: true,
      isOpenAssignedTabByType: { yours: true, others: false },
      isTyping: false,
    }
  }

  componentDidMount() {
    const { dispatch, currentRoom } = this.props
    this.refreshRooms()
    this.refreshRoom(true).then(() => {
      if (currentRoom) {
        dispatch(clearUnreadMessage(currentRoom))
      }
    })
  }

  componentWillMount() {
    const handlers = {
      connect: this.connect,
      message: this.message,
      offline: this.offline,
    }
    this.websocket = new WebSocketDevice('ap-northeast-1', this)
    this.websocket.connect(Config.IdentityPoolId, handlers).catch(() => {
      this.startPolling()
    })
  }

  updateUnreadStatus = () => {
    this.props.dispatch(updateUnreadStatus())
  }

  setAutoScroll = isAutoScroll => {
    if (this.props.isAutoScroll !== isAutoScroll) {
      this.props.dispatch(setAutoScroll(isAutoScroll))
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props
    this.websocket.close()
    clearTimeout(this.pollingRoomsTimer)
    clearTimeout(this.pollingMessageTimer)
    this.finishTypingInterval()
    dispatch(clearRoom())
  }

  componentWillUpdate(nextProps) {
    const { dispatch, rooms } = nextProps
    if (nextProps.params.id) {
      const room = lodash.find(rooms, { id: parseInt(nextProps.params.id, 10) })
      if (!room || room.operator_state === 'none') {
        clearTimeout(this.pollingMessageTimer)
        this.pollingMessageTimer = null

        this.finishTypingInterval()
        dispatch(clearRoom())
        this.context.router.replace('/operator')
      }
    }

    if (this.props.params.id && !nextProps.params.id) {
      dispatch(clearRoom())
    }
  }

  componentDidUpdate(prevProps) {
    const prevRoom = prevProps.currentRoom || {}
    const currentRoom = this.props.currentRoom || {}
    const { dispatch } = this.props
    if (prevRoom.id !== currentRoom.id) {
      if (prevRoom.uuid && this.websocket.device) {
        this.websocket.device.unsubscribe(prevRoom.uuid)
      }
      if (currentRoom.uuid && this.websocket.device) {
        this.websocket.device.subscribe(currentRoom.uuid, { qos: 1 })
      }
      if (currentRoom.operator_state && currentRoom.operator_state !== 'none') {
        this.refreshRoom(true).then(() => {
          dispatch(clearUnreadMessage(currentRoom))
        })
        clearTimeout(this.pollingMessageTimer)
        this.pollingMessageTimer = setTimeout(this.pollingMessage, 1000)
      }
    }
  }

  connect = () => {
    const session = this.context.store.getState().session
    if (this.websocket.device) {
      //  Stop polling and use websocket event when connect websocket
      clearTimeout(this.pollingRoomsTimer)
      clearTimeout(this.pollingMessageTimer)
      this.pollingRoomsTimer = null
      this.pollingMessageTimer = null

      if (this.props.currentRoom) {
        this.websocket.device.subscribe(this.props.currentRoom.uuid, { qos: 1 })
      }
      this.websocket.device.subscribe(`operator:${session.account_uuid}`, { qos: 1 })
    }
  }

  offline = () => {
    this.startPolling()
  }

  message = (topic, payload) => {
    const session = this.context.store.getState().session
    //  Ignore message if already leave room
    if (!this.props.currentRoom) return

    if (topic === `operator:${session.account_uuid}`) {
      //  Receive room when calling status has been changed
      const room = JSON.parse(payload)
      this.refreshCallingStatus(room)
    } else {
      //  Receive message
      const message = JSON.parse(payload)
      message.timestamp = moment(message.timestamp)
      this.props.dispatch(storeMessage(message))
    }
  }

  startPolling = () => {
    //  Start polling via HTTP instead of failed websocket
    if (!this.pollingMessageTimer) {
      this.pollingMessageTimer = setTimeout(this.pollingMessage, 0)
    }
    if (!this.pollingRoomsTimer) {
      this.pollingRoomsTimer = setTimeout(this.pollingRooms, 0)
    }
  }

  pollingRooms = () => {
    //  Fetch rooms via HTTP request
    const session = this.context.store.getState().session
    const { dispatch } = this.props

    if (!this.websocket || this.websocket.status === 'connected') return

    Promise.resolve()
      .then(() => {
        dispatch(fetchOperatorRooms(session))
      })
      .then(() => {
        clearTimeout(this.pollingRoomsTimer)
        this.pollingRoomsTimer = setTimeout(this.pollingRooms, 60000)
      })
  }

  pollingMessage = () => {
    //  Fetch message via HTTP request
    const session = this.context.store.getState().session
    const { dispatch, currentRoom, messages } = this.props

    if (!currentRoom) return
    if (!this.websocket || this.websocket.status === 'connected') return

    const lastMessage = lodash.last(
      lodash.filter(messages, message => message.type !== 'reset' && !message.temporary)
    )
    const lastReceived = lastMessage ? lastMessage.timestamp.format() : null

    dispatch(fetchMessages(session, currentRoom, lastReceived)).then(results => {
      const lastSent = (lodash.last(lodash.concat(messages, results.messages)) || {}).timestamp
      const elapsed = lastSent ? new Date() - new Date(lastSent) : 999999

      let wait
      if (elapsed < 20 * 1000) {
        // below 20 seconds -> fetch after 1 second
        wait = 1 * 1000
      } else if (elapsed < 1 * 60 * 1000) {
        // below 1 minute -> fetch after 5 seconds
        wait = 5 * 1000
      } else if (elapsed < 5 * 60 * 1000) {
        // below 5 minutes -> fetch after 1 minute
        wait = 60 * 1000
      } else {
        // above 5 minutes -> fetch after 5 minutes
        wait = 5 * 60 * 1000
      }

      clearTimeout(this.pollingMessageTimer)
      this.pollingMessageTimer = setTimeout(this.pollingMessage, wait)
    })
  }

  refreshCallingStatus = room => {
    const session = this.context.store.getState().session
    const { t, router } = this.context
    const { dispatch, currentRoom } = this.props

    //  Ignore updating in other room or updated by themself
    if (room.id !== currentRoom.id) return
    if (room.updated_by === session.id) {
      if (room.operator_state === 'none') {
        dispatch(clearRoom())
        router.push('/operator')
      }
      return
    }

    if (room.operator_state === 'none') {
      dispatch(clearRoom())
      router.push('/operator')
      dispatch(addNotice('info', t('operator.messages.resetByUser', { room: room.id })))
    }

    if (room.operator_state === 'assigned') {
      dispatch(clearRoom())
      router.push('/operator')
      dispatch(addNotice('info', t('operator.messages.pickByOther', { room: room.id })))
    }
  }

  handleSendMessage = data => {
    const session = this.context.store.getState().session
    const { dispatch, currentRoom, isAutoScroll } = this.props

    const type = data.type || 'text'
    if (!lodash.includes(['operator_typing', 'operator_stop_typing'], type)) {
      if (!data.text && !data.value && !data.image_file) return
    }
    if (rejectReadonlyUser(dispatch, this.context)) return
    if (!currentRoom) return

    //  Clear unread flag if bottom is displayed
    if (isAutoScroll) {
      this.updateUnreadStatus()
    }

    if (type !== 'operator_typing') {
      this.props.change('text', '')

      //  Shorten wait for next fetching to get response for this utterance if websocket has been failed
      if (this.websocket.status !== 'connected') {
        clearTimeout(this.pollingMessageTimer)
        this.pollingMessageTimer = setTimeout(this.pollingMessage, 1000)
      }

      //  Reset typing interval
      this.finishTypingInterval()
    }

    return dispatch(
      sendOperatorMessage(session, currentRoom, session.email, type, data.text, data.image_file)
    ).then(() => this.refreshRoom())
  }

  onChangeText = e => {
    const { role } = getCredentials(this.context)

    if (role === 'readonly') return
    if (!this.props.currentRoom) return

    clearTimeout(this.stopTypingTimer)
    if (!e.target.value) {
      this.stopTypingTimer = setTimeout(this.stopTyping, 5000)
    }

    if (!this.state.isTyping) {
      this.setState({ isTyping: true })
      this.handleSendMessage({ type: 'operator_typing' })
      this.typingTimer = setTimeout(this.finishTypingInterval, 40000)
    }
  }

  finishTypingInterval = () => {
    this.setState({ isTyping: false })
    clearTimeout(this.typingIntervalTimer)
  }

  stopTyping = () => {
    this.handleSendMessage({ type: 'operator_stop_typing' })
    this.finishTypingInterval()
  }

  refreshRooms = () => {
    const state = this.context.store.getState()
    const { dispatch } = this.props
    dispatch(fetchOperatorRooms(state.session))
  }

  refreshRoom = (isFetchMessages = false) => {
    const session = this.context.store.getState().session
    const { dispatch, currentRoom } = this.props
    if (!currentRoom) return Promise.resolve()

    if (!isFetchMessages) {
      return dispatch(fetchRoom(session, currentRoom.id))
    } else {
      dispatch(fetchRoom(session, currentRoom.id))
      dispatch(setCurrentRoom(currentRoom))
      return dispatch(fetchMessages(session, currentRoom))
    }
  }

  changeRoom = roomId => {
    this.finishTypingInterval()
    this.context.router.push(`/operator/${roomId}`)
  }

  pickUp = roomId => {
    const state = this.context.store.getState()
    const { dispatch } = this.props
    dispatch(pickUp(state.session, roomId))
  }

  hungUp = roomId => {
    const state = this.context.store.getState()
    const { dispatch, rooms } = this.props
    const room = lodash.find(rooms, { id: roomId })
    if (this.websocket.device) {
      this.websocket.device.unsubscribe(room.uuid)
    }
    dispatch(hungUp(state.session, roomId)).then(() => {
      this.context.router.push('/operator')
    })
  }

  toggleAssignedTab = () => {
    this.setState({ isOpenAssignedTab: !this.state.isOpenAssignedTab })
  }

  toggleCallingTab = () => {
    this.setState({ isOpenCallingTab: !this.state.isOpenCallingTab })
  }

  toggleAssignedTabByType = type => {
    const isOpenAssignedTabByType = lodash.cloneDeep(this.state.isOpenAssignedTabByType)
    isOpenAssignedTabByType[type] = !isOpenAssignedTabByType[type]

    this.setState({ isOpenAssignedTabByType: isOpenAssignedTabByType })
  }

  onSpeechRecognition = text => {
    this.props.change('text', text)
  }

  render() {
    return (
      <div className="row">
        <div className="dm-operator-layout">
          {this.renderRoomList()}
          {this.renderChatRoom()}
          {this.renderSlotState()}
        </div>
      </div>
    )
  }

  renderRoomList = () => {
    const { t } = this.context
    const { isOpenAssignedTab, isOpenCallingTab } = this.state

    const assignedHeaderClasses = classnames({
      header: true,
      'fixed-height': true,
      'is-opened': isOpenAssignedTab,
    })

    const callingHeaderClasses = classnames({
      header: true,
      'fixed-height': true,
      'is-opened': isOpenCallingTab,
    })

    return (
      <div>
        <div className="dm-operator-sessions">
          <ul className="sessions">
            <li className="title fixed-height">
              <div className="pull-left">{t('operator.status')}</div>
              <div className="pull-right">
                <button className="dm-btn btn btn-default btn-icon-refresh" onClick={this.refreshRooms} />
              </div>
            </li>
            <li className={assignedHeaderClasses} onClick={this.toggleAssignedTab}>
              <i className="dm-icon-status assigned" /> {t('operator.assigned')}
              <span className="opening-state" />
            </li>
            {this.renderAssignedRoomList()}
            <li className={callingHeaderClasses} onClick={this.toggleCallingTab}>
              <i className="dm-icon-status calling" /> {t('operator.calling')}
              <span className="opening-state" />
            </li>
            {this.renderCallingRoomList()}
          </ul>
        </div>
      </div>
    )
  }

  renderAssignedRoomList = () => {
    const session = this.context.store.getState().session
    const { isOpenAssignedTab } = this.state
    const { rooms } = this.props

    const assignedRooms = lodash
      .chain(rooms)
      .filter(room => room.operator_state === 'assigned')
      .sortBy(room => room.operator_called_at)
      .value()

    const assignedRoomsByType = lodash.groupBy(assignedRooms, room => {
      if (lodash.find(room.operators, { id: session.id })) return 'yours'
      else return 'others'
    })

    const roomListClasses = classnames({
      'room-list': true,
      assigned: true,
      'is-opened': isOpenAssignedTab,
    })

    return (
      <li className={roomListClasses}>
        <div className="assigned-types">
          <Collapse isOpened={isOpenAssignedTab}>
            <ul>
              {this.renderAssignedRoomsByType(assignedRoomsByType.yours, 'yours')}
              {this.renderAssignedRoomsByType(assignedRoomsByType.others, 'others')}
            </ul>
          </Collapse>
        </div>
      </li>
    )
  }

  renderAssignedRoomsByType = (rooms, type) => {
    if (lodash.isEmpty(rooms)) return

    const { t } = this.context
    const { isFetching } = this.props
    const currentRoom = this.props.currentRoom || {}
    const { timezone } = getCredentials(this.context)

    const operators = rooms[0].operators
    const operatorsId = operators.map(operator => operator.id).join(', ')

    const tabLabel = t(`operator.assignedTypes.${type}`)
    const isOpened = this.state.isOpenAssignedTabByType[type] || false

    const headerClasses = classnames({
      header: true,
      'fixed-height': true,
      'is-opened': isOpened,
    })

    return (
      <React.Fragment key={operatorsId}>
        <li className={headerClasses} onClick={() => this.toggleAssignedTabByType(type)}>
          <i className="dm-icon-status each-assigned-type" /> {tabLabel}
          <span className="opening-state" />
        </li>
        <li className="each-assigned-type">
          <Collapse isOpened={isOpened}>
            <ul>
              {lodash.map(rooms, room => (
                <RoomItem
                  key={room.id}
                  room={room}
                  selected={room.id === currentRoom.id}
                  onClick={this.changeRoom}
                  onHungUp={this.hungUp}
                  platform={room.application.platform}
                  operator_called_at={moment.utc(room.operator_called_at)}
                  timezone={timezone}
                  isFetching={isFetching}
                />
              ))}
            </ul>
          </Collapse>
        </li>
      </React.Fragment>
    )
  }

  renderCallingRoomList = () => {
    const { isOpenCallingTab } = this.state
    const { isFetching, rooms } = this.props
    const currentRoom = this.props.currentRoom || {}
    const { timezone } = getCredentials(this.context)

    const callingRooms = lodash
      .chain(rooms)
      .filter(room => room.operator_state === 'calling')
      .sortBy(room => room.operator_called_at)
      .value()

    const roomListClasses = classnames({
      'room-list': true,
      calling: true,
      'is-opened': isOpenCallingTab,
    })

    return (
      <li className={roomListClasses}>
        <Collapse isOpened={isOpenCallingTab}>
          <ul>
            {lodash.map(callingRooms, room => (
              <RoomItem
                key={room.id}
                room={room}
                selected={room.id === currentRoom.id}
                onClick={this.changeRoom}
                onPickUp={this.pickUp}
                platform={room.application.platform}
                operator_called_at={moment.utc(room.operator_called_at)}
                timezone={timezone}
                isFetching={isFetching}
              />
            ))}
          </ul>
        </Collapse>
      </li>
    )
  }

  renderChatRoom = () => {
    const { currentRoom, submitting, handleSubmit, messages, isAutoScroll, formValues } = this.props
    const { timezone } = getCredentials(this.context)

    const features = {
      iconUrl: this.props.currentRoom ? this.props.currentRoom.application.icon_url : undefined,
    }

    return (
      <div className="dm-chat force-normal is-operator">
        <ChatRoom
          mode="server"
          submitting={submitting}
          messages={messages}
          features={features}
          timezone={timezone}
          onChangeText={this.onChangeText}
          onSubmit={handleSubmit(this.handleSendMessage)}
          handleSendMessage={this.handleSendMessage}
          formValues={formValues}
          updateUnreadStatus={this.updateUnreadStatus}
          isAutoScroll={isAutoScroll}
          setAutoScroll={this.setAutoScroll}
          imageUploaderDisabled={!currentRoom}
          onSpeechRecognition={this.onSpeechRecognition}
        />
      </div>
    )
  }

  renderSlotState = () => {
    const { currentRoom } = this.props
    if (!currentRoom) {
      return <div className="slotState clearfix dm-operator-slot" />
    }
    const slots = currentRoom.slots
    let slotNodes
    if (slots.length > 0) {
      slotNodes = slots.map(slot => (
        <li className="list-unstyled" key={slot.key}>
          <label>{`${slot.key}:`}</label>
          {slot.value || '----'}
        </li>
      ))
    } else {
      slotNodes = <li className="list-unstyled">{'----'}</li>
    }
    return (
      <div className="slotState clearfix dm-operator-slot">
        <div className="field">
          <LabelWithTooltip name="operator.application" direction="left" />
          <ul>
            <li className="list-unstyled">{currentRoom.application.name || '----'}</li>
          </ul>
        </div>
        <div className="field">
          <LabelWithTooltip name="operator.bot" direction="left" />
          <ul>
            <li className="list-unstyled">{currentRoom.bot.name || '----'}</li>
          </ul>
        </div>
        <div className="field">
          <LabelWithTooltip name="operator.client" direction="left" />
          <ul>
            <li className="list-unstyled">
              {currentRoom.clients.map(client => client.name).join(', ') || '----'}
            </li>
          </ul>
        </div>
        <div className="field">
          <LabelWithTooltip name="operator.topic" direction="left" />
          <ul>
            <li className="list-unstyled">{currentRoom.topic.name || '----'}</li>
          </ul>
        </div>
        <div className="field">
          <LabelWithTooltip name="operator.slots" direction="left" />
          <ul>{slotNodes}</ul>
        </div>
      </div>
    )
  }
}

const OperatorForm = reduxForm({
  form: 'Operator',
  enableReinitialize: true,
  validate,
})(Operator)

export const mapStateToProps = (state, props) => {
  const currentRoom = lodash.first(lodash.filter(state.chat.rooms, { id: parseInt(props.params.id, 10) }))
  const account = lodash.filter(state.entities.accounts)[0]
  const formValues = getFormValues('Operator')(state) || {}
  return {
    account,
    rooms: lodash.filter(state.chat.rooms),
    currentRoom,
    messages: state.chat.messages,
    isFetching: isFetching(state),
    isAutoScroll: state.chat.isAutoScroll,
    formValues,
  }
}

export default connect(mapStateToProps)(OperatorForm)
